<template>
	<!--订单记录-->
	<div>
		<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ paddingTop: 0, paddingBottom: '16px' }">
			<template #title>
				<h6 class="font-semibold m-0">订单记录</h6>
			</template>
			<a-list :pagination="pagination" :loading="spinning" class="invoice-list" item-layout="horizontal" :split="false"
				:data-source="records">
				<a-list-item slot="renderItem" slot-scope="item" @click="() => { detail = item; showDetail = true }">
					<a-badge :status="item.paymentStatus | status" />
					<a-list-item-meta :title="item.purchaseTime | timeFmt"
						:description="item.paymentMethod | method2Text"></a-list-item-meta>
					<div class="amount">¥{{ item.totalPrice }}</div>
				</a-list-item>
			</a-list>
		</a-card>
		<a-modal title="订单详情" :visible="showDetail" @ok="showDetail=false" @cancel="showDetail=false">

		</a-modal>
	</div>
</template>

<script>

export default ({
	data() {
		return {
			records: [],
			spinning: true,
			pagination: {
				total: 0,
				pageSize: 10,
				current: 1,
				onChange: this.onChange
			},
			showDetail: false,
			detail: {}
		}
	},
	methods: {
		getData() {
			this.spinning = true
			this.$axios.get(`/api/platform/purchaseRecord/list?current=${this.pagination.current}&pageSize=${this.pagination.pageSize}`)
				.then(res => {
					this.spinning = false
					this.records = res.data.records
					this.pagination.current = res.data.current
					this.pagination.total = res.data.total
				})
		},
		onChange(current, pageSize) {
			this.pagination.current = current
			this.pagination.pageSize= pageSize
			this.getData()
		}
	},
	mounted() {
		this.getData()
	},
	filters: {
		status(val) {
			switch (val) {
				case "WAIT_BUYER_PAY":
					return "default"
				case "TRADE_CLOSED":
					return "fail"
				case "TRADE_SUCCESS":
				case "TRADE_FINISHED":
					return "success"
			}
		},
		timeFmt(val) {
			const date = new Date(val)
			return date.toLocaleString('zh-CN', { timeZone: 'Asia/Shanghai' })
		},
		method2Text(val) {
			let map = {
				'AliF2F': '支付宝',
				'Balance': '余额',
			}
			return map[val] ?? val
		}
	}
})

</script>

<style scoped>

.ant-list-item {
	padding: 10px;
}

.ant-list-item:hover {
	border-radius: 5px;
	background-color: aliceblue;
	cursor: pointer;
}

</style>