<!-- 
	This is the billing page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>

		<a-row type="flex" :gutter="24">

			<!-- Billing Info Column -->
			<a-col :span="24" :md="16">
				<a-row type="flex" :gutter="24">
					<a-col hidden :span="24" :xl="0" class="mb-24">

						<!-- Master Card -->
						<CardCredit></CardCredit>
						<!-- / Master Card -->

					</a-col>
					<a-col :span="12" :xl="12" class="mb-24" v-for="(salary, index) in salaries" :key="index">

						<!-- Salary Card -->
						<WidgetSalary :value="salary.value" :prefix="salary.prefix" :icon="salary.icon" :title="salary.title"
							:content="salary.content"></WidgetSalary>
						<!-- / Salary Card -->

					</a-col>
					<a-col :span="24" class="mb-24">

						<!-- Payment Methods Card -->
						<CardPaymentMethods></CardPaymentMethods>
						<!-- Payment Methods Card -->

					</a-col>
				</a-row>
			</a-col>
			<!-- / Billing Info Column -->

			<!-- Invoices Column -->
			<a-col :span="24" :md="8" class="mb-24">

				<!-- Invoices Card -->
				<CardInvoices></CardInvoices>
				<!-- / Invoices Card -->

			</a-col>
			<!-- / Invoices Column -->

		</a-row>

		<a-row hidden type="flex" :gutter="24">

			<!-- Billing Information Column -->
			<a-col :span="24" :md="16" class="mb-24">

				<!-- Billing Information Card -->
				<CardBillingInfo></CardBillingInfo>
				<!-- / Billing Information Card -->

			</a-col>
			<!-- Billing Information Column -->

			<!-- Your Transactions Column -->
			<a-col hidden :span="24" :md="8" class="mb-24">

				<!-- Your Transactions Card -->
				<CardTransactions :data="transactionsData"></CardTransactions>
				<!-- / Your Transactions Card -->

			</a-col>
			<!-- / Your Transactions Column -->

		</a-row>

	</div>
</template>

<script>

import CardCredit from "../components/Cards/CardCredit"
import WidgetSalary from "../components/Widgets/WidgetSalary"
import CardPaymentMethods from "../components/Cards/CardPaymentMethods"
import CardInvoices from "../components/Cards/CardInvoices"
import CardBillingInfo from "../components/Cards/CardBillingInfo"
import CardTransactions from "../components/Cards/CardTransactions"

import store from '@/store'

// "Your Transactions" list data.
const transactionsData = [
	{
		period: "NEWEST",
	},
	{
		title: "Netflix",
		datetime: "27 March 2021, at 12:30 PM",
		amount: "2,500",
		type: -1,// 0 is for pending, 1 is for deposit, -1 is for withdrawal.
		status: 'danger',
	},
	{
		title: "Apple",
		datetime: "27 March 2021, at 04:30 AM",
		amount: "2,000",
		type: 1,
		status: 'success',
	},
	{
		period: "YESTERDAY",
	},
	{
		title: "Stripe",
		datetime: "26 March 2021, at 12:30 AM",
		amount: "750",
		type: 1,
		status: 'success',
	},
	{
		title: "HubSpot",
		datetime: "26 March 2021, at 11:30 AM",
		amount: "1,050",
		type: 1,
		status: 'success',
	},
	{
		title: "Creative Tim",
		datetime: "26 March 2021, at 07:30 AM",
		amount: "2,400",
		type: 1,
		status: 'success',
	},
	{
		title: "Webflow",
		datetime: "26 March 2021, at 04:00 AM",
		amount: "Pending",
		type: 0,
		status: 'warning',
	},
]

export default ({
	components: {
		CardCredit,
		WidgetSalary,
		CardPaymentMethods,
		CardInvoices,
		CardBillingInfo,
		CardTransactions,
	},
	data() {
		return {
			// Associating "Your Transactions" list data with its corresponding property.
			transactionsData,
		}
	},
	computed: {
		salaries() {
			return [
				{
					value: store.state.userInfo.balance,
					prefix: "¥",
					icon: `
			<svg t="1688125042048" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11708" width="50" height="50"><path d="M398.517389 256.714779l235.342006 0 63.10627-86.570681c0 0 47.312562-57.732889-15.794731-83.421968-60.335157-24.580838-156.751067-22.655999-164.971285-22.452361-8.268314-0.203638-104.636129-2.128477-164.971285 22.452361-63.082734 25.688055-15.818267 83.421968-15.818267 83.421968L398.517389 256.714779z" fill="#ffffff" p-id="11709"></path><path d="M621.095697 301.054637 406.997528 301.054637c0 0-283.274692 105.657388-283.274692 397.165602 0 254.790964 311.719534 262.703167 390.323776 260.686231 78.623685 2.015913 390.323776-5.895267 390.323776-260.686231C904.370389 406.712025 621.095697 301.054637 621.095697 301.054637zM592.014358 654.969179l63.958684 0c14.856359 0 26.900673 11.93789 26.900673 26.894534 0 14.853289-12.170181 26.894534-27.00812 26.894534L551.712467 708.758246l0 115.895567c0 15.084557-12.193717 27.313066-27.472702 27.313066-15.172561 0-27.472702-12.446473-27.472702-27.313066L496.767064 708.758246l-97.339956 0c-14.915711 0-27.00812-11.93789-27.00812-26.894534 0-14.853289 11.965519-26.894534 26.721595-26.894534l97.626481 0 0-55.239092-96.52438 0c-15.366989 0-27.823696-12.325723-27.823696-27.76946 0-15.33629 12.554944-27.76946 27.823696-27.76946l62.394049 0-72.256674-72.256674c-9.638521-9.638521-9.674337-25.229614 0.069585-34.974559 9.677407-9.677407 25.282826-9.761318 34.974559-0.069585l83.372849 83.372849c6.481621 6.481621 8.599865 15.651468 6.342452 23.928992l5.38873 0c-1.397836-7.819083 0.924045-16.136515 6.953366-22.165836l84.854597-84.854597c9.863648-9.863648 25.746384-9.777691 35.595706 0.071631 9.917884 9.917884 9.881045 25.786293 0.070608 35.595706l-71.354118 71.353095 78.400604 0c15.366989 0 27.823696 12.325723 27.823696 27.76946 0 15.33629-12.554944 27.76946-27.823696 27.76946L551.712467 599.73111l0 55.239092L592.014358 654.970202z" fill="#ffffff" p-id="11710"></path></svg>`,
					title: "余额",
					content: "Balance",
				},
				{
					value: store.state.userInfo.integral,
					prefix: "",
					icon: `<svg t="1688123511998" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3883" width="200" height="200">
				<path d="M511.666 209.543c-167.416 0-302.275 134.859-302.275 302.263 0 167.412 134.859 302.268 302.275 302.268 167.397 0 302.253-134.855 302.253-302.268 0-167.403-134.856-302.263-302.253-302.263z m0 574.051c-150.05 0-271.793-121.757-271.793-271.787 0-150.035 121.744-271.782 271.793-271.782 150.035 0 271.779 121.747 271.779 271.782-0.001 150.03-121.745 271.787-271.779 271.787z m0 0" fill="#FFFFFF" p-id="3885"></path>
				<path d="M715.794 412.197c-2.438-6.154-9.825-8.613-15.976-4.922-6.149 2.459-8.612 9.844-4.918 15.988 13.519 28.29 20.894 57.797 20.894 88.544 0 111.906-90.994 202.919-202.909 202.919-6.144 0-12.294 4.925-12.294 12.306 0 6.15 4.918 12.287 12.294 12.287 125.434 0 226.29-102.068 226.29-226.281-1.231-35.669-8.612-68.866-23.381-100.841z m0 0M511.666 308.887c52.872 0 103.291 20.906 141.416 57.8 2.463 2.462 4.925 3.69 8.619 3.69 3.688 0 6.144-1.228 8.594-3.69 4.938-4.916 4.938-12.297 0-17.209-43.031-41.819-98.368-63.95-158.628-63.95-6.156 0-12.309 4.916-12.309 12.297-0.002 6.144 6.151 11.062 12.308 11.062z m0 0M432.95 698.743c-75.019-31.975-124.209-105.769-124.209-186.937 0-78.707 46.731-151.266 118.056-184.469 6.153-2.459 8.609-9.844 6.153-15.984-2.459-6.153-9.844-8.609-15.984-6.153-79.944 36.894-131.591 118.063-131.591 206.606 0 91.012 54.109 173.412 138.966 209.063 1.231 1.244 3.69 1.244 4.922 1.244 4.919 0 8.603-2.463 11.065-7.388 1.229-6.151-1.231-13.519-7.378-15.982z m0 0" fill="#FFFFFF" p-id="3886"></path>
				<path d="M477.219 557.313c3.69 0 7.381-1.238 9.847-3.687 7.372-7.383 8.6-15.994 2.453-27.063-2.453-2.45-9.837-13.525-24.597-30.74-2.456-2.454-3.69-4.916-6.144-7.381v-3.691h17.213c4.918 0 11.075-1.225 13.528-8.603v36.89c0 8.613 1.234 14.757 4.919 18.457 2.465 3.681 7.381 4.919 13.535 4.919H610.05c6.145 0 11.056-1.238 14.75-4.919 3.688-3.7 4.919-9.844 4.919-18.457v-92.234c0-8.605-1.231-14.759-6.145-18.446-4.924-4.919-12.312-7.381-22.137-6.15h-89.772c-7.384 0-13.541 1.231-17.228 6.15-3.685 3.687-4.919 11.068-4.919 19.678v39.356c-2.453-6.144-8.609-8.622-13.528-9.838h-17.213v-27.059h3.688c2.456-1.231 6.15-1.231 8.612-2.459 11.063-2.462 17.209-9.837 15.988-19.678-2.466-8.613-8.613-13.528-18.45-13.528h-3.694c-12.297 2.462-31.972 6.147-57.797 8.609h-8.609c-12.3 2.459-18.45 9.837-18.45 20.906 1.231 6.15 4.918 13.522 17.215 13.522h15.991c3.694 0 7.384-1.216 9.834-1.216v22.119H397.28c-11.065 1.241-15.984 7.381-17.215 17.219 0 14.763 8.609 17.229 15.994 17.229h24.597c-8.619 33.21-22.141 62.716-36.897 88.541-7.384 13.537-1.231 20.918 4.916 23.381 2.462 1.226 6.15 2.456 8.606 2.456 6.15 0 11.072-3.699 15.991-8.619 3.694-6.144 7.384-12.293 9.834-20.912v57.813c0 6.15 2.466 14.751 17.222 15.988 13.531-1.237 17.219-8.613 17.219-14.77v-81.162c1.231 2.463 2.456 3.694 3.685 4.918 7.384-1.226 11.068 2.461 15.987 2.461z m49.194-62.729V430.65c0-3.693 1.225-3.693 2.462-3.693h61.488c3.692 1.228 3.692 2.453 3.692 2.453v63.959c0 2.454-1.243 2.454-2.462 2.454h-63.956c-1.224 1.234-1.224-0.001-1.224-1.239z m0 0" fill="#FFFFFF" p-id="3887"></path>
				<path d="M541.169 538.862c-3.694-1.23-6.156-2.449-9.838-2.449-6.144 0-12.294 3.681-15.987 11.056-14.753 25.831-28.278 45.506-43.044 60.274-11.068 11.069-7.378 20.9-2.465 25.819 4.925 3.688 8.619 4.912 13.541 4.912 4.912 0 9.838-2.449 14.75-6.138 19.681-22.149 36.888-44.28 49.193-67.649 7.375-11.061 4.925-19.674-6.15-25.825z m0 0M640.787 607.743c-12.3-24.605-25.831-46.749-41.806-66.418-8.618-7.382-17.219-8.606-25.837-3.693-4.919 3.693-7.382 7.381-8.607 11.074-1.225 3.688 0 8.606 3.688 12.288 11.068 14.763 24.587 35.681 40.587 61.5 3.693 6.149 8.606 9.843 14.762 9.843 2.457 0 4.913 0 7.37-1.243 9.843-4.912 13.525-13.52 9.843-23.351z m0 0" fill="#FFFFFF" p-id="3888"></path>
			</svg>`,
					title: "积分",
					content: "Integral",
				},
			]
		}
	}
})

</script>

<style lang="scss"></style>