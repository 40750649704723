<template>
	<!-- Payment Methods Card -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ paddingTop: 0, }">
		<template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12">
					<h6 class="font-semibold m-0">余额充值</h6>
				</a-col>
				<a-col hidden :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
					<a-button type="primary">
						ADD NEW CARD
					</a-button>
				</a-col>
			</a-row>
		</template>
		<a-spin :spinning="spinning" tip="加载中...">
			<a-row :gutter="[24, 24]">
				<a-radio-group style="width: 100%;" default-value="cash" button-style="solid" v-model="payType">
					<a-col :span="8">
						<a-radio-button value="cdKey">
							cdKey
						</a-radio-button>
					</a-col>
					<a-col :span="8">
						<a-radio-button value="cash">
							在线支付
						</a-radio-button>
					</a-col>
				</a-radio-group>
			</a-row>
			<a-row :gutter="[24, 24]">
				<a-col v-if="payType == 'cash'" :span="18">
					<a-input v-model="value" prefix="￥" suffix="RMB" placeholder="填写金额" type="number" />
				</a-col>
				<a-col v-if="payType == 'cdKey'" :span="18">
					<a-input v-model="value" placeholder="输入cdKey以兑换余额" />
				</a-col>
				<a-col v-if="payType == 'commission'" :span="18">
					<a-input v-model="value" placeholder="填写金额" type="number" />
				</a-col>
				<a-col :span="6">
					<a-button @click="pay">充值</a-button>
				</a-col>
			</a-row>
		</a-spin>
		<a-modal v-model="cash.visible" title="在线支付" style="text-align: center;">
			<template slot="footer">
				<a-button key="back" @click="cash.visible = false">
					取消支付
				</a-button>
			</template>
			<vue-qr :text="cash.qrUrl" :size="200"></vue-qr>
			<p>请打开支付宝扫一扫,支付成功自动关闭</p>
		</a-modal>
	</a-card>
	<!-- Payment Methods Card -->
</template>

<script>
import vueQr from 'vue-qr'

export default ({
	components: {
		vueQr,
	},
	data() {
		return {
			spinning: false,
			payType: 'cash',
			value: '',
			cash: {
				qrUrl: '',
				purchaseRecordId: '',
				visible: false,
			}
		}
	},
	methods: {
		pay() {
			try {
				switch (this.payType) {
					case 'commission':
						break
					case 'cash':
						if (this.value <= 0) {
							this.$message.warn('请输入大于0的金额')
						}
						
						this.spinning = true
						this.$axios.post('/api/platform/charge/pay', { payMethod: 'AliF2F', amount: this.value })
							.then(res => {
								if (res.code === 0) {
									this.cash.qrUrl = res.data.qrCode
									this.cash.purchaseRecordId = res.data.purchaseRecordId
									this.cash.visible = true
									this.checkPurchase()
								}
								this.spinning = false
							})
						break
					case 'cdKey':
						this.$message.warn('cdKey无效')
						this.spinning = false
						break
				}
			} catch(e) {
			}
		},
		checkPurchase() {
			if (this.cash.purchaseRecordId) {
				this.$axios.get(`/api/platform/purchaseRecord/check?id=${this.cash.purchaseRecordId}`)
					.then(res => {
						if (this.cash.visible) {
							switch(res.data) {
								case 'WAIT_BUYER_PAY': // 等待付款
									break;
								case 'TRADE_SUCCESS': // 支付成功
									this.cash.visible = false
									this.$message.info('支付成功')
									break;
								case 'TRADE_CLOSED': // 订单关闭
								default:
									this.cash.visible = false
									this.$message.wran('订单关闭')
							}
						}
					})
			}
			if (this.cash.visible) {
				setTimeout(() => {
					this.checkPurchase()
				}, 3000)
			}
		},
	}
})

</script>

<style>
/* 在Chrome浏览器下 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

/* 在Firefox浏览器下 */
input[type="number"] {
	-moz-appearance: textfield;
}
</style>